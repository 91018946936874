import { AxiosResponse } from "axios"
import api from "helpers/api"
import { useCallback, useState } from "react"
import { UseApiJobsProps } from "./types"

const getMethod = (method: "getTyped" | "post" | "put") => {
    return api[method].bind(api)
}

export const useApiJobs = () => {
    const [progress, setProgress] = useState(0)
    const [jobString, setJobString] = useState<string>("")
    const [cache, setCatch] = useState<
        Record<
            string,
            {
                lastFetched: Date
                data: any
            }
        >
    >({})

    const getCacheValue = (v: any) => {
        const cacheData = cache[JSON.stringify(v)]
        if (cacheData) {
            const isExpired =
                Date.now() - cacheData.lastFetched.getTime() > 60 * 1000
            if (isExpired) {
                setCatch((prev) => {
                    delete prev[JSON.stringify(v)]
                    return {
                        ...prev,
                    }
                })
                return
            } else {
                return cacheData.data
            }
        }
        return
    }

    const clearCatchData = useCallback(
        async (params: object[]) => {
            return setCatch((prev) => {
                const newCatch = { ...prev }
                params.forEach((el) => {
                    delete newCatch[JSON.stringify(el)]
                })
                return newCatch
            })
        },
        [setCatch]
    )

    const fetchJobs = useCallback(
        async <JobData, RequestData, ResponseData, ReturnedData>(
            props: UseApiJobsProps<
                JobData,
                RequestData,
                ResponseData,
                ReturnedData
            >,
            _jobs: JobData[],
            result?: ReturnedData
        ): Promise<ReturnedData> => {
            const apiMethod = getMethod(props.method ?? "getTyped")
            let _result = result ?? props.initialResult
            const jobs = [..._jobs]
            const currentJob = jobs.pop()
            if (!currentJob) {
                setProgress(0)
                return _result
            }
            if (props.getProcessString) {
                setJobString(props.getProcessString(currentJob as any))
            }
            setProgress(
                props.initialLength
                    ? 100 - 100 * (jobs.length / props.initialLength)
                    : 0
            )
            try {
                const requestData = props.getRequestData(currentJob) as any
                const cacheResponse = getCacheValue(requestData)
                const response = cacheResponse
                    ? { data: cacheResponse }
                    : ((await (apiMethod as any)(
                          props.url,
                          {},
                          props.getRequestData(currentJob) as any
                      )) as AxiosResponse<ResponseData>)
                if (!cacheResponse) {
                    setCatch((prev) => ({
                        ...prev,
                        [JSON.stringify(requestData)]: {
                            data: response.data,
                            lastFetched: new Date(),
                        },
                    }))
                }
                const newResult = props.getResultData(
                    response.data,
                    _result,
                    currentJob
                )
                return await fetchJobs(props, jobs, newResult)
            } catch {
                return await fetchJobs(props, jobs, _result)
            }
        },
        [cache]
    )

    return {
        progress,
        showProgress: progress > 0,
        jobString,
        clearCatchData,
        fetchJobs,
    }
}
