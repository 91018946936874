import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { getFieldsForViewData } from "pages/NameGenerationSettings/helper"
import { Dispatch, SetStateAction } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import TableWithCheckboxes from "../TableWithCheckboxes"

type Series = { id: Uuid; label: string }

interface ITableModelsProps {
    activeSeries: Series | null
    selectedGroupId?: Uuid
    fromModelId?: Uuid
    values: Record<string, Uuid[]> | null
    setValues: Dispatch<SetStateAction<Record<string, Uuid[]> | null>>
    dataSeries: Series[] | []
    isSeriesFetched: boolean
}

const TableModels = ({
    activeSeries,
    selectedGroupId,
    values,
    setValues,
    fromModelId,
    dataSeries,
    isSeriesFetched,
}: ITableModelsProps) => {
    const activeSeriesId = activeSeries?.id ?? ""

    const { data, isFetched, isFetching } = useQuery({
        queryKey: ["get-models", activeSeries?.id, selectedGroupId],
        enabled: !!selectedGroupId && !!activeSeries?.id,
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/model", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                    itemsGroups: selectedGroupId,
                    series: activeSeries?.id,
                })
                .then(async (data) => {
                    return data.entities?.map((item) => ({
                        id: item.id,
                        label: getFieldsForViewData(item.fields)
                            .label as string,
                    }))
                }),
        initialData: [],
    })

    const onAllCheckboxClick = () => {
        const allDataIds = data.map((item) => item.id)

        setValues((state) => {
            const currentValue = state?.[activeSeriesId] ?? []

            return {
                ...state,
                [activeSeriesId]: allDataIds.every((item) =>
                    currentValue?.includes(item)
                )
                    ? currentValue?.filter((item) => !allDataIds.includes(item))
                    : allDataIds,
            }
        })
    }

    const onCheckboxClick = (val: Uuid) => {
        setValues((state) => {
            const currentValue = state?.[activeSeriesId] ?? []

            return {
                ...state,
                [activeSeriesId]: currentValue.includes(val)
                    ? currentValue.filter((item) => item !== val)
                    : [...(currentValue || []), val],
            }
        })
    }

    return (
        <TableWithCheckboxes
            disabledId={fromModelId}
            label={`Все модели ${activeSeries?.label ?? ""}`}
            data={data}
            loading={
                (!isFetched && !!dataSeries?.length) ||
                (isFetching && !activeSeries?.id) ||
                (!activeSeries?.id && !isSeriesFetched)
            }
            values={values?.[activeSeriesId]}
            onCheckboxClick={onCheckboxClick}
            onAllCheckboxClick={onAllCheckboxClick}
        />
    )
}

export default TableModels
