import { Modal } from "antd";
import Link from "antd/es/typography/Link";
import { Flex } from "components/UI/Flex";
import { UTable } from "components/UTable";
import { IQueryBuilderShowResult } from "./types";

export default function QueryBuilderShowResult({
  openModal,
  setOpenModal,
  loading,
  found,
  totalPages,
  currentPage,
  nextPage,
}: IQueryBuilderShowResult) {
  return (
    <Modal
      open={openModal}
      onCancel={() => setOpenModal(false)}
      title="Найденная номенклатура"
      cancelButtonProps={{ hidden: true }}
      okText="Закрыть"
      onOk={() => setOpenModal(false)}
    >
      <Flex.Col fullWidth>
        {found && (
          <UTable
            data={found}
            loading={loading}
            search
            searchCondition={(row, search) =>
              row.name.toLowerCase().includes(search.toLowerCase())
            }
            maxRowsVisible={5}
            onScrollFinish={() => {
              if (currentPage < totalPages) {
                nextPage();
              }
            }}
            columns={[
              {
                columnName: "Название",
                render(v) {
                  return (
                    <Link
                      href={`/configuration/nomenclature/update-${v.id}`}
                      target="_blank"
                    >
                      {v.name}
                    </Link>
                  );
                },
              },
            ]}
          />
        )}
      </Flex.Col>
    </Modal>
  );
}
