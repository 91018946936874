import { Modal, Spin } from "antd"
import { Flex } from "components/UI/Flex"
import WrapperForAsyncSelect from "components/UI/WrapperForAsyncSelect"
import { useEffect, useState } from "react"

import { Uuid } from "types"
import ButtonCopyAllSettings from "./ButtonCopyAllSettings"
import ButtonSelectAllSettings from "./ButtonSelectAllSettings"
import styles from "./modalCopySettingsTo.module.scss"
import TableModels from "./TableModels"
import TableSeries from "./TableSeries"

interface IValue {
    value: string
    label: string
}

interface IModalCopySettingsToProps
    extends Pick<React.ComponentProps<typeof Modal>, "open"> {
    fromGroup?: IValue
    fromProducer?: IValue
    fromSeriesId?: Uuid
    fromModelId?: Uuid
    onCancel: () => void
    onSuccess: () => void
}

const ModalCopySettingsTo = ({
    open,
    onCancel,
    onSuccess,
    ...props
}: IModalCopySettingsToProps) => {
    const { fromGroup, fromProducer, fromSeriesId, fromModelId } = props
    const currentProps = {
        fromGroupId: fromGroup?.value,
        fromProducerId: fromProducer?.value,
        fromSeriesId,
        fromModelId,
    }

    const [selectedProducer, setSelectedProducer] = useState<IValue>()
    const [selectedGroup, setSelectedGroup] = useState<IValue>()
    const [activeSeries, setActiveSeries] = useState<{
        id: Uuid
        label: string
    } | null>(null)
    const [selectedSeries, setSelectedSeries] = useState<Uuid[]>([])
    const [selectedModels, setSelectedModels] = useState<Record<
        Uuid,
        Uuid[]
    > | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [dataSeries, setDataSeries] = useState<{ id: Uuid; label: string }[]>(
        []
    )
    const [isFetched, setIsFetched] = useState(false)

    const isDisabledSeries =
        currentProps.fromGroupId === selectedGroup?.value &&
        currentProps.fromProducerId === selectedProducer?.value

    useEffect(() => {
        setSelectedSeries([])
        setSelectedModels(null)
        setDataSeries([])
        setActiveSeries(null)
    }, [selectedProducer, selectedGroup])

    useEffect(() => {
        setSelectedProducer(fromProducer)
        setSelectedGroup(fromGroup)
        setSelectedSeries([])
        setSelectedModels(null)
    }, [fromGroup, fromProducer, open])

    return (
        <Modal
            width={620}
            className={styles.customModal}
            rootClassName={styles.rootCustomModal}
            open={open}
            onCancel={onCancel}
            title="Скопировать настройки на"
            cancelButtonProps={{ hidden: true }}
            footer
        >
            <Spin spinning={isLoading}>
                <Flex.Col gap={12} fullWidth align="start">
                    <WrapperForAsyncSelect
                        dataUrl="producer"
                        onChange={(state) => {
                            setSelectedProducer(state)
                            setSelectedGroup(undefined)
                        }}
                        label="Поставщик"
                        value={selectedProducer}
                        className={`${styles.customSelect} ${styles["z-6"]}`}
                    />
                    <WrapperForAsyncSelect
                        dataUrl="items-group"
                        isDisabled={!selectedProducer}
                        params={{ producer: selectedProducer?.value }}
                        onChange={(state) => setSelectedGroup(state)}
                        label="Группа"
                        value={selectedGroup}
                        className={`${styles.customSelect} ${styles["z-5"]}`}
                    />
                    <Flex.Row gap={12} fullWidth>
                        <TableSeries
                            fromSeriesId={fromSeriesId}
                            selectedGroupId={selectedGroup?.value}
                            selectedProducerId={selectedProducer?.value}
                            activeSeries={activeSeries}
                            selectedModels={selectedModels}
                            setActiveSeries={setActiveSeries}
                            values={selectedSeries}
                            setValues={setSelectedSeries}
                            setDataSeries={setDataSeries}
                            activeDisabled={isDisabledSeries}
                            setIsFetched={setIsFetched}
                        />
                        <TableModels
                            fromModelId={fromModelId}
                            selectedGroupId={selectedGroup?.value}
                            activeSeries={activeSeries}
                            values={selectedModels}
                            setValues={setSelectedModels}
                            dataSeries={dataSeries}
                            isSeriesFetched={isFetched}
                        />
                    </Flex.Row>
                    <Flex.Row gap={12} align="center" fullWidth>
                        <ButtonCopyAllSettings
                            setIsLoading={setIsLoading}
                            selectedGroupId={selectedGroup?.value}
                            selectedProducerId={selectedProducer?.value}
                            selectedSeries={selectedSeries}
                            selectedModels={selectedModels}
                            onCancel={onCancel}
                            onSuccess={onSuccess}
                            {...currentProps}
                        />
                        <ButtonSelectAllSettings
                            setIsLoading={setIsLoading}
                            setSelectedModels={setSelectedModels}
                            setSelectedSeries={setSelectedSeries}
                            isDisabledSeries={isDisabledSeries}
                            selectedGroupId={selectedGroup?.value}
                            selectedProducerId={selectedProducer?.value}
                            dataSeries={dataSeries}
                            {...currentProps}
                        />
                    </Flex.Row>
                </Flex.Col>
            </Spin>
        </Modal>
    )
}

export default ModalCopySettingsTo
