import { useQuery } from "@tanstack/react-query"
import { Switch } from "antd"
import { Flex } from "components/UI/Flex"
import React, { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Uuid } from "types"
import api from "../../helpers/api"
import { PicturesGeneratorParams } from "./index"
import { PicturesGeneratorFileUploader } from "./PicturesGeneratorFileUploader"

export const PicturesGeneratorPartAttributes: React.FC<{
    params: PicturesGeneratorParams
    target: "model" | "series"
    onFillAttribute: (attributeId: Uuid, valueId?: Uuid) => void
    filled: {
        [attributeId: Uuid]: string
    }
    resetPreviews: () => void
}> = ({ params, onFillAttribute, filled, target, resetPreviews }) => {
    const [sortAttributes, setSortAttributes] = useState(true)
    const [isEntranceDoor, setIsEntranceDoor] = useState(false)
    const [isInterior, setIsInterior] = useState(false)
    const [isViewIds, setIsViewIds] = useState(false)

    const [attributes, setAttributes] = useState<
        {
            attribute: {
                valuesList: Record<Uuid, string>
                name: string
                id: Uuid
            }
            sortChoosing: number
        }[]
    >([])

    const [attributesAvailableValues, setAttributesAvailableValues] = useState<
        {
            attributeId: Uuid
            configurableAttributeValues: Uuid[]
        }[]
    >([])

    const [attributesValues, setAttributesValues] = useState<
        {
            attributeId: Uuid
            attribute: {
                valuesList: Record<Uuid, string>
            }
        }[]
    >([])

    useEffect(() => {
        ;(async () => {
            // todo rewrite to react-query nested dependent queries
            const attributesByGroupProducerWithSorting = await api.getTyped<
                {
                    attribute: {
                        name: string
                        id: Uuid
                        valuesList: Record<Uuid, string>
                    }
                    sortChoosing?: number
                }[]
            >("attribute/group_producer_attributes_linking_details", {
                group: params.group,
                producer: params.producer,
            })

            const attributesByGroupProducerSeries = await api.getTyped<
                {
                    attributeId: Uuid
                    attribute: {
                        valuesList: Record<Uuid, string>
                    }
                }[]
            >("attribute/list-by-group-and-producer-and-series", {
                producer: params.producer,
                group: params.group,
                series: params.series,
            })

            let availableValues: {
                attributeId: Uuid
                configurableAttributeValues: Uuid[]
            }[] = []

            if (params.model?.value) {
                availableValues = await api.getTyped(
                    "attribute/list-by-series-and-model",
                    {
                        series: params.series,
                        model: params.model?.value,
                    }
                )
            }

            const attributesAssignedSorts = await api
                .getTyped<any[]>("v1/pictures-generator/list-attributes-sorts")
                .then((v) =>
                    Object.fromEntries(
                        v.map((curr) => [curr.attribute.id, curr.index])
                    )
                )

            setAttributesAvailableValues(availableValues)
            setAttributesValues(attributesByGroupProducerSeries)
            setAttributes(
                attributesByGroupProducerWithSorting
                    .map((item, i) => ({
                        ...item,
                        sortChoosing: Number(
                            item.sortChoosing || 1000 + i // 1000 to indicate it was frontend-side assigned
                        ),
                    }))
                    .sort((a, b) => a.sortChoosing - b.sortChoosing)
                    .filter((v) =>
                        attributesByGroupProducerSeries.some(
                            (valuesRow) =>
                                valuesRow.attributeId === v.attribute.id
                        )
                    )
                    .filter((row) => {
                        if (!params.model?.value) return true

                        const availibleValuesForCurrAttribute =
                            availableValues.find(
                                (v) => v.attributeId === row.attribute.id
                            )

                        if (!availibleValuesForCurrAttribute) {
                            return false
                        }

                        return (
                            availibleValuesForCurrAttribute
                                .configurableAttributeValues.length > 0
                        )
                    })
                    .sort((a, b) => {
                        return (
                            (attributesAssignedSorts[a.attribute.id] ??
                                100_000) -
                            (attributesAssignedSorts[b.attribute.id] ?? 100_000)
                        )
                    })
            )
        })()
    }, [params])

    const { data: valuesAlternativeNames } = useQuery<
        {
            attributeId: Uuid
            valuesAlternativeNames: Record<Uuid, string>
        }[]
    >({
        queryKey: [
            "configurator-alternative-names",
            params.group,
            params.producer,
        ],
        queryFn: () =>
            api.getTyped(
                "attribute/group_producer_attributes_all_list_alt_names",
                {
                    group: params.group,
                    producer: params.producer,
                }
            ),
    })

    const buildValuesListByAttribute = (
        row: {
            attribute: {
                name: string
                id: Uuid
            }
            sortChoosing: number
        },
        compatibilityRoute: Record<Uuid, Uuid>
    ) => {
        const values = Object.entries(
            attributesValues.find((v) => v.attributeId === row.attribute.id)
                ?.attribute.valuesList ?? {}
        ).map(([attributeValueId, attributeValueLabel]) => {
            const isAvailable = params.model?.value
                ? attributesAvailableValues
                      .find((v) => v.attributeId === row.attribute.id)
                      ?.configurableAttributeValues?.some(
                          (configurableAttributeId) =>
                              configurableAttributeId === attributeValueId
                      )
                : true

            const initialName = attributeValueLabel
            const alternativeName = (valuesAlternativeNames?.find(
                (v) => v.attributeId === row.attribute.id
            )?.valuesAlternativeNames ?? {})[attributeValueId]

            return {
                id: attributeValueId,
                isAvailable,
                isCompatible: true,
                finalName: alternativeName ?? initialName,
                initialName,
                alternativeName,
            }
        })
        const comparator = new Intl.Collator()

        return values.sort((a, b) =>
            comparator.compare(
                a.finalName.toLowerCase().trim(),
                b.finalName.toLowerCase().trim()
            )
        )
    }

    const [attributeModelValuesFiles, setAttributeModelValuesFiles] = useState<
        {
            id: Uuid
            attributeId: Uuid
            valueId: Uuid
            fileUrl: string
            zIndex: number
        }[]
    >([])

    const { refetch: refetchLinks } = useQuery({
        queryKey: [
            "pictures-links-listing",
            params.model?.value,
            params.producer,
            params.group,
            params.series,
            isInterior,
        ],
        queryFn: () =>
            api.getTyped<
                {
                    id: Uuid
                    file: {
                        id: Uuid
                        public_link: string
                    }
                    attribute: { id: Uuid }
                    attributeValueId: Uuid
                    zIndex: number
                }[]
            >(`v1/pictures-generator/${target}/list-links`, {
                model: params.model?.value,
                itemsGroup: params.group,
                series: params.series,
                producer: params.producer,
                interior: isInterior,
            }),
        onSuccess: (response) => {
            setAttributeModelValuesFiles(
                response.map(
                    ({ file, attribute, attributeValueId, zIndex, id }) => ({
                        valueId: attributeValueId,
                        attributeId: attribute.id,
                        fileUrl: file.public_link,
                        zIndex,
                        id,
                    })
                )
            )
        },
    })

    const handleUploadedFile = async (
        file: number,
        attribute: Uuid,
        attributeValue: Uuid
    ) => {
        const { data } = await api.post<
            any,
            {
                attributeValueId: Uuid
                attribute: { id: Uuid }
                file: {
                    public_link: string
                }
                id: Uuid
            }
        >(`v1/pictures-generator/${target}/link`, null, {
            file,
            attribute,
            attributeValue,
            model: params.model?.value,
            itemsGroup: params.group,
            series: params.series,
            producer: params.producer,
            interior: isInterior,
        })

        setAttributeModelValuesFiles([
            ...attributeModelValuesFiles,
            {
                attributeId: data.attribute.id,
                valueId: data.attributeValueId,
                fileUrl: data.file.public_link,
                zIndex: 0,
                id: data.id,
            },
        ])
    }

    const handleSetZIndex = async (attribute: Uuid, zIndex: number) => {
        return toast.promise(
            api.post<
                any,
                {
                    id: Uuid
                }
            >(`v1/pictures-generator/${target}/z-index/update`, null, {
                attribute,
                model: params.model?.value,
                itemsGroup: params.group,
                series: params.series,
                producer: params.producer,
                payload: zIndex,
            }),
            {
                loading: "Загрузка...",
                success: () => {
                    refetchLinks()
                    return <b>Обновлено!</b>
                },
                error: (error = "Не удалось сбросить, неизвестная ошибка") => {
                    return <b>{String(error)}</b>
                },
            },
            {
                duration: 1500,
            }
        )
    }

    const handleResetFile = async (attribute: Uuid, attributeValue: Uuid) => {
        await toast.promise(
            api.post<
                any,
                {
                    attributeValue: Uuid
                    attribute: { id: Uuid }
                    file: {
                        public_link: string
                    }
                }
            >(`v1/pictures-generator/${target}/clear`, null, {
                attribute,
                attributeValue,
                model: params.model?.value,
                itemsGroup: params.group,
                series: params.series,
                producer: params.producer,
                interior: isInterior,
            }),
            {
                loading: "Загрузка...",
                success: () => {
                    onFillAttribute(attribute)
                    setAttributeModelValuesFiles(
                        attributeModelValuesFiles.filter(
                            (v) =>
                                !(
                                    v.attributeId === attribute &&
                                    v.valueId === attributeValue
                                )
                        )
                    )

                    return <b>Сброшено!</b>
                },
                error: (error = "Не удалось сбросить, неизвестная ошибка") => {
                    return <b>{String(error)}</b>
                },
            },
            {
                duration: 1500,
            }
        )
    }

    useEffect(() => {
        setAttributeModelValuesFiles([])
        setIsEntranceDoor(params.groupName === "Дверь входная")
        setIsInterior(false)
    }, [params])

    useEffect(() => {
        resetPreviews()
    }, [isInterior])

    const findFileByAttribute = useCallback(
        (
            attributeId: Uuid,
            attributeValue: Uuid
        ):
            | {
                  attributeId: Uuid
                  valueId: Uuid
                  fileUrl: string
                  zIndex: number
                  id: Uuid
              }
            | undefined => {
            return attributeModelValuesFiles.find((v) => {
                return (
                    v.attributeId === attributeId &&
                    v.valueId === attributeValue
                )
            })
        },
        [attributeModelValuesFiles]
    )

    return (
        <>
            {isEntranceDoor && (
                <Flex.Row gap={10} justify="start" padding={"0 0 15px 15px"}>
                    <Switch
                        checked={isInterior}
                        onChange={() => setIsInterior((state) => !state)}
                    />
                    <label className="form-check-label">
                        Внешняя / Внутренняя
                    </label>
                </Flex.Row>
            )}
            <div className="control-in">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="m-0">Свойства</h5>
                    <Flex.Row gap={20}>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isViewIds}
                                onChange={() => setIsViewIds((v) => !v)}
                            />
                            <label className="form-check-label">
                                Показать id
                            </label>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={sortAttributes}
                                onChange={() => setSortAttributes((v) => !v)}
                            />
                            <label className="form-check-label">
                                Сортировать по очередности
                            </label>
                        </div>
                    </Flex.Row>
                </div>

                {attributes
                    .map((row) => ({
                        ...row,
                        zIndex:
                            attributeModelValuesFiles.find((v) => {
                                return v.attributeId === row.attribute.id
                            })?.zIndex ?? 0,
                    }))
                    .sort((a, b) =>
                        sortAttributes ? b?.zIndex - a?.zIndex : 0
                    )
                    .map((row) => {
                        // todo rewrite to dry
                        const [, compatibilityRoute] = Object.keys(filled)
                            .sort((a, b) => {
                                const aSort = attributes.find(
                                    (atr) => atr.attribute.id.toString() === a
                                )!.sortChoosing
                                const bSort = attributes.find(
                                    (atr) => atr.attribute.id.toString() === b
                                )!.sortChoosing

                                return aSort - bSort
                            })
                            .reduce<[boolean, Record<string, string>]>(
                                ([done, object], curr) => {
                                    if (done) {
                                        return [done, object]
                                    }

                                    if (curr === row.attribute.id.toString()) {
                                        return [true, object]
                                    }

                                    return [
                                        false,
                                        {
                                            ...object,
                                            [curr]: filled[curr],
                                        },
                                    ]
                                },
                                [false, {}]
                            )

                        const zIndex =
                            attributeModelValuesFiles.find((v) => {
                                return v.attributeId === row.attribute.id
                            })?.zIndex ?? 0

                        const isAnyLayersLinked =
                            attributeModelValuesFiles.some((v) => {
                                return v.attributeId === row.attribute.id
                            })

                        return (
                            <div
                                className="row mb-3"
                                key={`attribute-row-${row.attribute.id}`}
                            >
                                <label className="col-xl-3 pt-0 col-form-label">
                                    [{row.sortChoosing}
                                    {isViewIds &&
                                        ` | ${row.attribute.id}`}]{" "}
                                    {row.attribute.name} <br />
                                    {isAnyLayersLinked && (
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                color: "blue",
                                            }}
                                            onClick={() =>
                                                handleSetZIndex(
                                                    row.attribute.id,
                                                    Number(
                                                        prompt(
                                                            "Пожалуйста, укажите новый номер слоя"
                                                        )
                                                    ) ?? 0
                                                )
                                            }
                                        >
                                            [{zIndex}] Изменить очередь
                                        </span>
                                    )}
                                </label>
                                <div
                                    className="col-xl-9"
                                    style={{
                                        borderBottom: "1px solid #e1e1e1",
                                    }}
                                >
                                    {buildValuesListByAttribute(
                                        row,
                                        compatibilityRoute
                                    ).map((option) => {
                                        if (
                                            !option.isAvailable ||
                                            !option.isCompatible
                                        ) {
                                            return null
                                        }

                                        const link = findFileByAttribute(
                                            row.attribute.id,
                                            option.id
                                        )

                                        return (
                                            <div key={`option-${option.id}`}>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex">
                                                        <div
                                                            className="d-inline-block"
                                                            style={{
                                                                width: "200px",
                                                            }}
                                                        >
                                                            {option.finalName}
                                                        </div>

                                                        <div className="d-inline-block">
                                                            {link ? (
                                                                <div>
                                                                    <div className="d-flex">
                                                                        <img
                                                                            src={
                                                                                link.fileUrl
                                                                            }
                                                                            alt="img"
                                                                            style={{
                                                                                height: "50px",
                                                                                width: "auto",
                                                                                maxWidth:
                                                                                    "150px",
                                                                                border: "1px solid #b7b7b7",
                                                                            }}
                                                                        />
                                                                        <div className="d-inline-block">
                                                                            <div
                                                                                style={{
                                                                                    paddingLeft:
                                                                                        "10px",
                                                                                    cursor: "pointer",
                                                                                    color: "blue",
                                                                                    fontSize: 14,
                                                                                    lineHeight:
                                                                                        "16px",
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleResetFile(
                                                                                        row
                                                                                            .attribute
                                                                                            .id,
                                                                                        option.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Сбросить
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <PicturesGeneratorFileUploader
                                                                    onUploaded={(
                                                                        fileId
                                                                    ) =>
                                                                        handleUploadedFile(
                                                                            fileId,
                                                                            row
                                                                                .attribute
                                                                                .id,
                                                                            option.id
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                accept=".png"
                                                                checked={
                                                                    filled[
                                                                        row
                                                                            .attribute
                                                                            .id
                                                                    ] ===
                                                                    option.id
                                                                }
                                                                disabled={
                                                                    !findFileByAttribute(
                                                                        row
                                                                            .attribute
                                                                            .id,
                                                                        option.id
                                                                    )
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    onFillAttribute(
                                                                        row
                                                                            .attribute
                                                                            .id,
                                                                        e.target
                                                                            .checked
                                                                            ? option.id
                                                                            : undefined
                                                                    )
                                                                }}
                                                            />
                                                            <label className="form-check-label">
                                                                Превью
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
            </div>
        </>
    )
}
