import { Card } from "antd"
import { Flex } from "components/UI/Flex"
import { useCallback, useEffect } from "react"
import ModalCopySettingsTo from "./components/ModalCopySettingsTo"
import styles from "./nameGenerationSettings.module.scss"
import { useNameGenerationSettingsStore } from "./NameGenerationSettings.store"
import NameMasks from "./NameMasks"
import SelectionProducersAndGroups from "./SelectionProducersAndGroups"
import SettingValuesAttributesModel from "./SettingValuesAttributesModel"
import SettingValuesAttributesSeries from "./SettingValuesAttributesSeries"

const NameGenerationSettings = () => {
    const {
        activeGroupId,
        activeProducerId,
        setModal,
        modal,
        activeSeriesId,
        setValue,
        reset,
    } = useNameGenerationSettingsStore()

    useEffect(() => {
        return reset()
    }, [])

    const onCancel = useCallback(() => {
        setModal(false, "active")
        setModal(null, "modelId")
        setModal(null, "seriesId")
    }, [])

    const onSuccess = useCallback(() => {
        setValue(true, "refetchSeries")
        setValue(true, "refetchModels")
    }, [])

    return (
        <>
            <h1 className="h3 mb-3">Настройки формирования названий</h1>
            <Card className={styles.card}>
                <Flex.Col gap={24} align="start" fullWidth>
                    <SelectionProducersAndGroups />
                    {!!activeGroupId && !!activeProducerId && <NameMasks />}
                    {!!activeSeriesId &&
                        !!activeGroupId &&
                        !!activeProducerId && (
                            <>
                                <SettingValuesAttributesSeries />
                                <SettingValuesAttributesModel />
                            </>
                        )}
                </Flex.Col>
            </Card>

            <ModalCopySettingsTo
                open={modal?.active}
                fromGroup={
                    modal?.group as {
                        value: string
                        label: string
                    }
                }
                fromProducer={
                    modal?.producer as {
                        value: string
                        label: string
                    }
                }
                fromSeriesId={modal?.seriesId}
                fromModelId={modal?.modelId}
                onCancel={onCancel}
                onSuccess={onSuccess}
            />
        </>
    )
}

export default NameGenerationSettings
