import "dayjs/locale/ru"
import Home from "pages/Home"
import PageNotFound from "pages/PageNotFound"
import Test from "pages/Test"
import { Route, Switch } from "react-router-dom"
import { innerRoutes } from "routes/routes"
import { createAdditionalRoute, createBaseRoute } from "routes/tools"

const routes = innerRoutes

const createOrUpdateRoutes = routes.filter(
    (el) => el.create || el.update || el.createAndUpdate
)

export default function SecureRouter() {
    const baseRouteElements = routes.map((route) => createBaseRoute(route))
    const additionalRouteElements = createOrUpdateRoutes.map((route) =>
        createAdditionalRoute(route)
    )

    return (
        <Switch>
            {baseRouteElements}
            {additionalRouteElements}

            <Route exact path={"/"} component={Home} />

            <Route exact path={["/test"]} render={() => <Test />} />

            <Route render={() => <PageNotFound />} />
        </Switch>
    )
}
