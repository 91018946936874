import { AsyncSelect } from "components/UI"
import { AsyncPaginateProps } from "react-select-async-paginate"

import api from "helpers/api"
import { Uuid } from "types"
import { ItemsGroupEntity } from "types/api"
import styles from "./wrapperForAsyncSelect.module.scss"

type SelectProps<ResponseType> = {
    dataUrl: string
    params?: object
    page?: number
    noMessage?: string
    loadMessage?: string
    noDefaultOption?: boolean
    isClearable?: boolean
    onChange: (v: any, noReset?: true) => void
    onFirstOptionLoad?: () => void
    skipValues?: Uuid[]
    externalOptions?: any[]
    filter?: (v: any) => any
    transformResponse?: (v: ResponseType) => any
    regionDependent?: boolean
    label: string
    link?: {
        text: string
        href: string
    }
} & Omit<AsyncPaginateProps<any, any, any, any>, "loadOptions" | "onChange">

export default function WrapperForAsyncSelect<ResponseType>({
    label,
    link,
    externalOptions,
    ...props
}: SelectProps<ResponseType>) {
    const isUrlItemsGroup = props.dataUrl === "items-group"
    const producer = (props.params as { producer?: string })?.producer

    const loadOptions = async () => {
        if (!producer) return { options: [], hasMore: false }

        const response = await api.get<ItemsGroupEntity[]>(
            `v1/producer/${producer}/items-groups`
        )

        const options = response.data.map((item) => ({
            label: item.name,
            value: item.id,
        }))

        return {
            options,
            hasMore: false,
        }
    }

    return (
        <div className={styles.wrapperSelect}>
            <label>{label}</label>
            <AsyncSelect
                loadOptions={
                    isUrlItemsGroup && !!producer ? loadOptions : undefined
                }
                {...props}
            />
            {!!link && (
                <a target="_blank" rel="noreferrer" href={link.href}>
                    {link.text}
                </a>
            )}
        </div>
    )
}
