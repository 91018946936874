import { Button } from "antd"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useState } from "react"
import { Uuid } from "types"
import ModalResultCopyingSettings from "../ModalResultCopyingSettings"
import { CopyCompatibilitesResponse } from "../ModalResultCopyingSettings/ModalResultCopyingSettings"

interface ButtonCopyAllSettingsProps {
    setIsLoading: (val: boolean) => void
    fromModelId?: Uuid
    fromSeriesId?: Uuid
    fromProducerId?: Uuid
    fromGroupId?: Uuid
    selectedGroupId?: Uuid
    selectedProducerId?: Uuid
    selectedSeries: Uuid[]
    selectedModels: Record<string, Uuid[]> | null
    onCancel: () => void
    onSuccess: () => void
}

const ButtonCopyAllSettings = ({
    setIsLoading,
    fromModelId,
    fromSeriesId,
    fromProducerId,
    fromGroupId,
    selectedGroupId,
    selectedProducerId,
    selectedSeries,
    selectedModels = {},
    onCancel,
    onSuccess,
}: ButtonCopyAllSettingsProps) => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState<CopyCompatibilitesResponse>()
    const { showNotification } = useNotifications()

    const copyMasks = () => {
        setIsLoading(true)

        api.post(
            fromModelId
                ? `v1/model/${fromModelId}/naming/copy`
                : `v1/group/${fromGroupId}/producer/${fromProducerId}/series/${fromSeriesId}/naming/copy`,
            {},
            {
                itemsGroup: selectedGroupId,
                producer: selectedProducerId,
                ...(selectedSeries && selectedSeries.length > 0
                    ? { series: selectedSeries }
                    : {}),
                ...(selectedModels &&
                Object.values(selectedModels).flat().length > 0
                    ? { models: Object.values(selectedModels).flat() }
                    : {}),
            }
        )
            .then((res) => {
                const responseData = res.data as CopyCompatibilitesResponse
                onSuccess()

                if (responseData.result === "SUCCESS") {
                    showNotification({
                        type: "success",
                        message: "Копирование произошло успешно",
                    })
                    onCancel()

                    return
                }

                setData(responseData)
                setOpen(true)
            })
            .catch((error) => {
                console.log(error)
                showNotification({
                    type: "danger",
                    message: error.message,
                })
            })
            .finally(() => setIsLoading(false))
    }

    const idDisabled =
        !Object.values(selectedModels ?? {}).flat().length &&
        !selectedSeries?.length

    return (
        <>
            <Button type="primary" onClick={copyMasks} disabled={idDisabled}>
                Скопировать
            </Button>
            {data && (
                <ModalResultCopyingSettings
                    open={open}
                    onCancel={() => setOpen(false)}
                    data={data}
                />
            )}
        </>
    )
}

export default ButtonCopyAllSettings
