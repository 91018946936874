import { useQuery } from "@tanstack/react-query"
import { Layout } from "components/layouts"
import { DisabledInput } from "components/UI"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { usePageId } from "hooks/usePageId"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import StandardKitDetailIsVerified from "./StandardKitDetail.IsVerified"
import StandardKitDetailPicture from "./StandardKitDetail.Picture"
import classes from "./StandartKitDetail.module.sass"
import { StandardKit } from "./types"

export default function StandardKitDetail() {
    const comparator = new Intl.Collator()
    const { id } = usePageId()

    const { data, isFetching } = useQuery<StandardKit>({
        queryKey: [id, "reference/standard-kit"],
        enabled: !!id,
        queryFn: () => api.getTyped("reference/standard-kit/" + id),
    })

    const showNomenclatures = useMemo(() => {
        if (!data) return false

        return data.nomenclatures.every((el) => typeof el === "object")
    }, [data])

    return (
        <Layout.Detail
            pageTitle="Стандартный комплект"
            loading={isFetching}
            contentTitle={data?.name ?? ""}
            infoPart={id ? <StandardKitDetailPicture id={id} /> : undefined}
        >
            {data && (
                <Flex.Col
                    gap={20}
                    fullWidth
                    margin={[25, 0, 0]}
                    className={classes.root}
                >
                    <FormLine label="Уникальный номер комплекта">
                        <DisabledInput value={data.id} />
                    </FormLine>

                    <FormLine label="Название комплекта">
                        <DisabledInput value={data.name} />
                    </FormLine>

                    {data.model && (
                        <FormLine label="Модель">
                            <Link
                                to={`/model/update-${data.model.id}`}
                                target="_blank"
                            >
                                {data.model.name}
                            </Link>
                        </FormLine>
                    )}

                    {data.headNomenclature && (
                        <FormLine label="Обобщенная номенклатура">
                            <Link
                                to={`/configuration/nomenclature/update-${data.headNomenclature.id}`}
                                target="_blank"
                            >
                                {data.headNomenclature.name}
                            </Link>
                        </FormLine>
                    )}

                    {showNomenclatures && (
                        <FormLine label="Номенклатура входящая в комплект">
                            <Flex.Col gap={10} align="start">
                                <UTable
                                    data={data.nomenclatures}
                                    sort={(a, b) =>
                                        comparator.compare(
                                            a.nomenclature.itemsGroup.name,
                                            b.nomenclature.itemsGroup.name
                                        )
                                    }
                                    columns={[
                                        {
                                            columnName: "",
                                            width: 20,
                                            render: (row) =>
                                                row.nomenclature.itemsGroup
                                                    .name,
                                        },
                                        {
                                            columnName: "Номенклатура",
                                            render: (row) => (
                                                <Link
                                                    to={`/configuration/nomenclature/update-${row.nomenclature.id}`}
                                                    target="_blank"
                                                >
                                                    {row.nomenclature.name}
                                                </Link>
                                            ),
                                            width: 60,
                                        },
                                        {
                                            columnName: "шт.",
                                            render: (row) => row.pcCount,
                                            width: 10,
                                        },
                                        {
                                            columnName: "Компл.",
                                            render: (row) => row.kitCount,
                                            width: 10,
                                        },
                                    ]}
                                />
                            </Flex.Col>
                        </FormLine>
                    )}

                    {data?.price && (
                        <FormLine label="Итоговая стоимость за комплект">
                            <DisabledInput
                                value={data.price.toFixed(2).toString()}
                            />
                        </FormLine>
                    )}

                    {data?.deliveryTime && (
                        <FormLine label="Срок поставки">
                            <DisabledInput
                                value={data.deliveryTime.toString() + " д."}
                            />
                        </FormLine>
                    )}

                    {id && (
                        <StandardKitDetailIsVerified
                            isVerified={data?.verified}
                            id={id}
                        />
                    )}
                </Flex.Col>
            )}
        </Layout.Detail>
    )
}
