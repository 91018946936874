import { useQuery } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"
import { AttributeLink, AttributeListItem, Uuid } from "types"
import api from "../../../helpers/api"
import { useReadyPictureConfiguratorStore } from "./ReadyPictureConfigurator.store"

export const ReadyPictureConfiguratorPartAttributes = () => {
    const {
        activeGroup,
        activeProducer,
        activeSeries,
        activeModel,
        filledAttributes: filled,
        onFillAttribute,
        applied,
    } = useReadyPictureConfiguratorStore()

    const { data: attributesByGroupProducerWithSorting } = useQuery({
        queryKey: [
            "group_producer_attributes_linking_details",
            activeGroup,
            activeProducer,
        ],
        enabled: !!activeGroup && !!activeProducer && !!applied,
        queryFn: () =>
            api.getTyped<AttributeListItem[]>(
                `attribute/group_producer_attributes_linking_details`,
                {
                    group: activeGroup!.value,
                    producer: activeProducer!.value,
                }
            ),
    })

    const { data: attributesByGroupProducerSeries } = useQuery({
        queryKey: [
            "attribute/list-by-group-and-producer-and-series",
            activeGroup,
            activeProducer,
            activeSeries,
        ],
        enabled: !!activeGroup && !!activeProducer && !!activeSeries,
        queryFn: () =>
            api.getTyped<AttributeLink[]>(
                `attribute/list-by-group-and-producer-and-series`,
                {
                    group: activeGroup!.value,
                    producer: activeProducer!.value,
                    series: activeSeries!.value,
                }
            ),
    })

    const { data: attributesAvailableValues } = useQuery({
        queryKey: [
            "attribute/list-by-series-and-model",
            activeSeries,
            activeModel,
        ],
        enabled: !!activeModel && !!activeSeries && !!applied,
        queryFn: () =>
            api.getTyped<AttributeLink[]>(
                `attribute/list-by-series-and-model`,
                {
                    series: activeSeries!.value,
                    model: activeModel!.value,
                }
            ),
        initialData: [],
    })

    const {
        data: attributesAssignedSorts,
        remove: removeAttributesAssignedSorts,
    } = useQuery({
        queryKey: ["v1/pictures-generator/list-attributes-sorts"],
        queryFn: () =>
            api
                .getTyped<any[]>(`v1/pictures-generator/list-attributes-sorts`)
                .then((v) =>
                    Object.fromEntries(
                        v.map((curr) => [curr.attribute.id, curr.index])
                    )
                ),
    })

    useEffect(() => {
        if (!applied) {
            removeAttributesAssignedSorts()
        }
    }, [removeAttributesAssignedSorts, applied])

    const attributes = useMemo(() => {
        if (
            !attributesByGroupProducerWithSorting ||
            !attributesByGroupProducerSeries ||
            !attributesAssignedSorts
        )
            return []

        const a = attributesByGroupProducerWithSorting
            .map((item, i) => ({
                ...item,
                sortChoosing: Number(
                    item.sortChoosing || 1000 + i // 1000 to indicate it was frontend-side assigned
                ),
            }))
            .sort((a, b) => a.sortChoosing - b.sortChoosing)

            .filter((v) =>
                attributesByGroupProducerSeries.some(
                    (valuesRow) => valuesRow.attributeId === v.attribute.id
                )
            )

            .filter((row) => {
                if (!activeModel?.value) return true

                const availibleValuesForCurrAttribute =
                    attributesAvailableValues.find(
                        (v) => v.attributeId === row.attribute.id
                    )

                if (!availibleValuesForCurrAttribute) {
                    return false
                }

                return (
                    availibleValuesForCurrAttribute.configurableAttributeValues
                        ?.length > 0
                )
            })

        return a.sort((a, b) => {
            return (
                (attributesAssignedSorts[a.attribute.id] ?? 100_000) -
                (attributesAssignedSorts[b.attribute.id] ?? 100_000)
            )
        })
    }, [
        attributesByGroupProducerWithSorting,
        attributesByGroupProducerSeries,
        attributesAssignedSorts,
        attributesAvailableValues,
        activeModel,
    ])

    const { data: valuesAlternativeNames } = useQuery<
        {
            attributeId: Uuid
            valuesAlternativeNames: Record<Uuid, string>
        }[]
    >({
        queryKey: [
            "configurator-alternative-names",
            activeGroup,
            activeProducer,
        ],
        enabled: !!activeGroup && !!activeProducer,
        queryFn: () =>
            api.getTyped(
                "attribute/group_producer_attributes_all_list_alt_names",
                {
                    group: activeGroup!.value,
                    producer: activeProducer!.value,
                }
            ),
    })

    const buildValuesListByAttribute = (row: AttributeListItem) => {
        const values = Object.entries(
            attributesByGroupProducerSeries?.find(
                (v) => v.attributeId === row.attribute.id
            )?.attribute.valuesList ?? {}
        ).map(([attributeValueId, attributeValueLabel]) => {
            const isAvailable = activeModel?.value
                ? attributesAvailableValues
                      .find((v) => v.attributeId === row.attribute.id)
                      ?.configurableAttributeValues?.some(
                          (configurableAttributeId) =>
                              configurableAttributeId === attributeValueId
                      )
                : true

            const initialName = attributeValueLabel
            const alternativeName = (valuesAlternativeNames?.find(
                (v) => v.attributeId === row.attribute.id
            )?.valuesAlternativeNames ?? {})[attributeValueId]

            return {
                id: attributeValueId,
                isAvailable,
                finalName: alternativeName ?? initialName,
                initialName,
                alternativeName,
            }
        })

        const comparator = new Intl.Collator()

        return values.sort((a, b) =>
            comparator.compare(
                a.finalName.toLowerCase().trim(),
                b.finalName.toLowerCase().trim()
            )
        )
    }

    // const [_, setAttributeModelValuesFiles] = useState<
    //     {
    //         id: Uuid
    //         attributeId: Uuid
    //         valueId: Uuid
    //         fileUrl: string
    //         zIndex: number
    //     }[]
    // >([])

    useQuery({
        queryKey: [
            "pictures-links-listing",
            activeModel,
            activeProducer,
            activeGroup,
            activeSeries,
        ],
        queryFn: () =>
            api.getTyped<
                {
                    id: Uuid
                    file: {
                        id: number
                        public_link: string
                    }
                    attribute: { id: Uuid }
                    attributeValue: Uuid
                    zIndex: number
                }[]
            >(`v1/pictures-generator/model/list-links`, {
                model: activeModel?.value,
                itemsGroup: activeGroup?.value,
                series: activeSeries?.value,
                producer: activeProducer?.value,
            }),
        // onSuccess: (response) =>
        //     setAttributeModelValuesFiles(
        //         response.map(
        //             ({ file, attribute, attributeValue, zIndex, id }) => ({
        //                 valueId: attributeValue,
        //                 attributeId: attribute.id,
        //                 fileUrl: file.public_link,
        //                 zIndex,
        //                 id,
        //             })
        //         )
        //     ),
    })

    // useEffect(() => {
    //     setAttributeModelValuesFiles([])
    // }, [activeGroup, activeModel, activeProducer, activeSeries])

    const [isViewIds, setIsViewIds] = useState(false)

    return (
        <>
            <div className="control-in">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="m-0">Свойства</h5>

                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={isViewIds}
                            onChange={() => setIsViewIds((v) => !v)}
                        />
                        <label className="form-check-label">Показать id</label>
                    </div>
                </div>

                {attributes.map((row) => {
                    return (
                        <div
                            className="row mb-3"
                            key={`attribute-row-${row.attribute.id}`}
                        >
                            <label className="col-xl-3 pt-0 col-form-label">
                                {isViewIds && `${row.attribute.id} | `}
                                {row.attribute.name}
                            </label>
                            <div
                                className="col-xl-9"
                                style={{
                                    borderBottom: "1px solid #e1e1e1",
                                }}
                            >
                                {buildValuesListByAttribute(row).map(
                                    (option) => {
                                        if (!option.isAvailable) {
                                            return null
                                        }

                                        return (
                                            <div key={`option-${option.id}`}>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="d-flex">
                                                        <div
                                                            className="d-inline-block"
                                                            style={{
                                                                width: "400px",
                                                            }}
                                                        >
                                                            {isViewIds && (
                                                                <>
                                                                    <span
                                                                        style={{
                                                                            display:
                                                                                "inline-block",
                                                                            height: "20px",
                                                                            overflow:
                                                                                "hidden",
                                                                            width: "34px",
                                                                            lineHeight:
                                                                                "28px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            option.id
                                                                        }
                                                                    </span>{" "}
                                                                    |
                                                                </>
                                                            )}
                                                            {option.finalName}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                accept=".png"
                                                                checked={
                                                                    filled[
                                                                        row
                                                                            .attribute
                                                                            .id
                                                                    ] ===
                                                                    option.id
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    onFillAttribute(
                                                                        row
                                                                            .attribute
                                                                            .id,
                                                                        e.target
                                                                            .checked
                                                                            ? option.id
                                                                            : undefined
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
