import api from "../../../../helpers/api"
import { DEL_ROW } from "../../../../store/listing/actions"
import { SET_SELECT_STATUS } from "../../../../store/selected/actions"
import { clearCheckedForRemove } from "../../index"

const chunkArray = (array: number[], chunk: number) => {
    const newArray = []
    for (let i = 0; i < array.length; i += chunk) {
        newArray.push(array.slice(i, i + chunk))
    }
    return newArray
}

export const handleClickBatchActions = (
    checkedForRemove: any[],
    pageSlug: any,
    dispatch: any,
    history: any,
    historyRouter: any,
    showNotification: (v: any) => void
) => {
    const itemsPerTimeout = 20

    const countStatuses = (array: any[], removeArr: any[]) => {
        const parts = Math.ceil(checkedForRemove.length / itemsPerTimeout)

        if (array.length === parts) {
            const failed = array.filter((el) => el !== 200)

            if (failed.length > 0) {
                checkedForRemove = []
                showNotification({
                    type: "danger",
                    message: "Произошла ошибка при удалении.",
                })
            } else {
                removeArr.forEach((id) => {
                    dispatch(DEL_ROW({ id }))
                })

                clearCheckedForRemove()

                dispatch(SET_SELECT_STATUS(false))
                showNotification({
                    type: "success",
                    message: "Элементы успешно удалены.",
                })
                setTimeout(() => historyRouter.go(0), 1500)
            }
        }
    }

    const statusArr: any[] = []
    const parts = Math.ceil(checkedForRemove.length / itemsPerTimeout)
    const chunkedArr = chunkArray(checkedForRemove, itemsPerTimeout)

    if (
        checkedForRemove.length !== 0 &&
        !window.confirm(
            `Вы действительно хотите удалить элементы? (количество: ${checkedForRemove.length})`
        )
    ) {
        return
    }

    for (let i = 0; i < parts; i++) {
        setTimeout(
            (function (index) {
                return function () {
                    const chunk = { idsToDelete: chunkedArr[index] }

                    api.post(`listing/delete/${pageSlug}`, {}, chunk)
                        .then((res) => {
                            statusArr.push(res.status)
                            countStatuses(statusArr, chunkedArr[index])
                        })
                        .catch((error) => {
                            statusArr.push(error.response.data.status)
                            countStatuses(statusArr, chunkedArr[index])
                        })
                }
            })(i),
            1000 * (i + 1)
        )
    }
}
