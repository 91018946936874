import { Checkbox, Spin } from "antd"
import { UTable } from "components/UTable"
import { Uuid } from "types"
import styles from "./tableWithCheckboxes.module.scss"

type Data = { id: Uuid; label: string }

interface ITableWithCheckboxesProps
    extends Pick<
        React.ComponentProps<typeof UTable>,
        "onRowClick" | "isRowActive" | "loading"
    > {
    data?: Data[]
    label: string
    onCheckboxClick: (val: Uuid) => void
    onAllCheckboxClick: () => void
    disabledId?: Uuid
    values?: Uuid[]
    getIndeterminateValue?: (val: { id: Uuid }) => boolean
}

const TableWithCheckboxes = ({
    label,
    loading,
    values = [],
    data = [],
    onAllCheckboxClick,
    onCheckboxClick,
    disabledId,
    getIndeterminateValue,
    ...props
}: ITableWithCheckboxesProps) => {
    const filteredData = data.filter((item) => item.id !== disabledId)

    const isItemChecked = (item: Data) => values.includes(item.id)

    const isIndeterminate =
        !!filteredData.length &&
        filteredData.some((item) => isItemChecked(item)) &&
        !filteredData.every((item) => isItemChecked(item))

    const isAllChecked =
        !!filteredData.length &&
        filteredData.every((item) => isItemChecked(item))

    return (
        <Spin spinning={loading} wrapperClassName={styles.spin}>
            <UTable<Data>
                maxRowsVisible={9}
                data={data as Data[]}
                {...props}
                columns={[
                    {
                        columnName() {
                            return (
                                <Checkbox
                                    onClick={onAllCheckboxClick}
                                    indeterminate={isIndeterminate}
                                    checked={isAllChecked}
                                    disabled={!filteredData.length}
                                />
                            )
                        },
                        render(v) {
                            const isChecked = values.includes(v.id)

                            const indeterminate =
                                getIndeterminateValue &&
                                getIndeterminateValue(v)

                            return (
                                <Checkbox
                                    onClick={() => onCheckboxClick(v.id)}
                                    checked={isChecked}
                                    disabled={disabledId === v.id}
                                    indeterminate={!isChecked && indeterminate}
                                />
                            )
                        },
                        width: 14,
                    },
                    {
                        columnName: label,
                        render(v) {
                            return v.label
                        },
                    },
                ]}
            />
        </Spin>
    )
}

export default TableWithCheckboxes
