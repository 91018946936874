import { CloseCircleTwoTone } from "@ant-design/icons"
import { Button, Col, Modal, Result, Row } from "antd"
import { Typography } from "antd/lib"
import { Flex } from "components/UI/Flex"
import React from "react"

import { Uuid } from "types"
import styles from "./modalResultCopyingSettings.module.scss"

type DestinationItem = {
    code: Uuid
    name: string
}

type MissingValue = {
    id: Uuid
    name: string
}

type FailReason = {
    missingValues: MissingValue[]
    reason: "MISSING_ATTRIBUTES"
    subject: DestinationItem
}

enum DestinationType {
    "SERIES" = "серии",
    "MODEL" = "модели",
}

enum CopyResult {
    Success = "SUCCESS",
    Failed = "FAILED",
    PartialSuccess = "PARTIAL_SUCCESS",
}

export type CopyCompatibilitesResponse = {
    copiedCount: number
    destinationType: keyof typeof DestinationType
    destination: DestinationItem[]
    failReason: FailReason | null

    innerResults: CopyCompatibilitesResponse[] | null
    result: CopyResult
    source: DestinationItem
    failedCount: number
}

interface ModalResultCopyingSettingsProps
    extends Pick<React.ComponentProps<typeof Modal>, "open" | "onCancel"> {
    data: CopyCompatibilitesResponse
}

type DataProps = {
    data: CopyCompatibilitesResponse
}

const Title = ({ data }: DataProps) => {
    const copyToType = DestinationType[data.destinationType]
    const failReason = data.failReason
    const missingValuesCount = failReason?.missingValues.length ?? 0
    const copyToName = failReason?.subject.name
    const missingValues = failReason?.missingValues.map((item, index) => (
        <React.Fragment key={item.id}>
            <Typography.Text mark>{item.name}</Typography.Text>
            {index < failReason.missingValues.length - 1 && ", "}
        </React.Fragment>
    ))

    if (CopyResult.Failed === data.result) {
        return (
            <>
                <CloseCircleTwoTone rev={undefined} className={styles.icon} />
                <Typography.Text className="text-align-start">
                    Копирование не произведено, у {copyToType}{" "}
                    <Typography.Text type="danger">
                        <b>{copyToName}</b>
                    </Typography.Text>{" "}
                    {missingValuesCount > 1
                        ? "отсутствуют свойства"
                        : "отсутствует свойство"}{" "}
                    {missingValues}
                </Typography.Text>
            </>
        )
    }

    return null
}

function Info({ data }: DataProps) {
    return (
        <>
            <Row
                align={"middle"}
                justify={"start"}
                className="m-bottom-md"
                gutter={[10, 0]}
            >
                <Col>
                    <Typography.Text className="text-align-start">
                        Количество скопированных записей -{" "}
                    </Typography.Text>
                    <Typography.Text strong>{data.copiedCount}</Typography.Text>
                </Col>
                <Col>
                    <Typography.Text className="text-align-start">
                        Количество пропущенных записей -{" "}
                    </Typography.Text>
                    <Typography.Text strong>{data.failedCount}</Typography.Text>
                </Col>
            </Row>
            <Row className="fw" style={{ maxHeight: 250, overflow: "auto" }}>
                {data.innerResults && (
                    <Col span={24}>
                        {data.innerResults.map((result) =>
                            result.innerResults?.map((el, idx) => (
                                <Flex.Row
                                    key={`${data.source.code}-${idx}`}
                                    align="start"
                                    className="attribute-copy-result-header"
                                >
                                    <Title data={el} />
                                </Flex.Row>
                            ))
                        )}
                    </Col>
                )}
            </Row>
        </>
    )
}

const ModalResultCopyingSettings = ({
    open,
    onCancel,
    data,
}: ModalResultCopyingSettingsProps) => {
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            footer={
                <Button danger type="primary" onClick={onCancel}>
                    Назад
                </Button>
            }
        >
            <Result
                className="copy-result-container"
                extra={<Info data={data} />}
            />
        </Modal>
    )
}

export default ModalResultCopyingSettings
