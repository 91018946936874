import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

export const usePaginationHelper = () => {
    const history = useHistory()

    const categoryID = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues["main"]?.category
    )

    const sortedSettings =
        useSelector(
            (state: { [key: string]: any }) =>
                state.savedUserSettings?.sortedColumns
        ) || []
    const columnName = sortedSettings.columnName
    const sortMethod = sortedSettings.sortMethod

    const bufferTemp = useSelector(
        (state: { [key: string]: any }) => state.bufferTemp
    )
    const filterQuery = bufferTemp?.filter.replaceAll("filter=", "") || ""
    const attributesQuery = bufferTemp?.attributes || ""

    return (apiPath: string, currentPage: number, pageSize: number) => {
        let query = `count_on_page=${
            pageSize || 20
        }&method=${sortMethod}&page=${currentPage || 1}&sort=${columnName}`
        let filtersURL = `${
            filterQuery.length > 0 ? `&filter=${filterQuery}` : ""
        }`
        let attributesURL = `${
            attributesQuery.length > 0
                ? `&attributeFilter=${attributesQuery}`
                : ""
        }`

        query = query + filtersURL + attributesURL

        if (categoryID && categoryID !== 1) {
            history.push(`?cat=${categoryID}&` + query)
        } else {
            history.push("?" + query)
        }

        if (bufferTemp.q) {
            history.push(`${apiPath}?q=${bufferTemp.q}&${query}`)
        }
    }
}
