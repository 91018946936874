import { Flex } from "components/UI/Flex"
import WrapperForAsyncSelect from "components/UI/WrapperForAsyncSelect"
import { useNameGenerationSettingsStore } from "../NameGenerationSettings.store"
import styles from "./selectionProducersAndGroups.module.scss"

const SelectionProducersAndGroups = () => {
    const { setValue, activeProducerId, activeGroupId } =
        useNameGenerationSettingsStore()

    return (
        <>
            <h5>Выберите поставщика и группу для настроек названий у серий</h5>
            <Flex.Col
                className={`control-in ${styles.container}`}
                gap={32}
                fullWidth
                align="start"
            >
                <WrapperForAsyncSelect
                    dataUrl="producer"
                    link={
                        activeProducerId
                            ? {
                                  text: "В настройки поставщика",
                                  href: `/catalog/producer/update-${activeProducerId}`,
                              }
                            : undefined
                    }
                    onChange={(state) => {
                        setValue(state.value, "producer", state)
                        setValue(null, "group")
                        setValue(null, "series")
                    }}
                    label="Поставщик"
                />
                <WrapperForAsyncSelect
                    dataUrl="items-group"
                    isDisabled={!activeProducerId}
                    params={{ producer: activeProducerId }}
                    onChange={(state) => {
                        setValue(state.value, "group", state)
                        setValue(null, "series")
                    }}
                    link={
                        activeGroupId
                            ? {
                                  text: "В настройки группы",
                                  href: `/catalog/items-group/update-${activeGroupId}`,
                              }
                            : undefined
                    }
                    label="Группа"
                />
            </Flex.Col>
        </>
    )
}

export default SelectionProducersAndGroups
