import { Checkbox, Row, Spin } from "antd"
import { DebounceInput } from "components/UI"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import React, { useMemo } from "react"
import { Uuid } from "types"
import styles from "./producerAttributesValuesChooser.module.scss"

interface ItemsGroupRowsChooserRow {
    id: Uuid
    label: string
    alt?: string
    accounting?: string
}
interface ItemsGroupRowsChooserProps {
    rows: ItemsGroupRowsChooserRow[]
    onChangeAltName: (row: Record<Uuid, string>) => void
    onChangeAccountingName: (row: Record<Uuid, string>) => void
    loading?: boolean
    unpublishedValues: Uuid[]
    unpublishAttribute: (code: Uuid) => void
    unpublishLoading?: Uuid
    setUnpublishLoading: (value: Uuid) => void
}

type TableData = {
    published: boolean
} & ItemsGroupRowsChooserRow

function PublishCheckbox({
    record,
    onChangeRow,
    unpublishLoading,
}: {
    record: TableData
    onChangeRow: (v: Uuid) => void
    unpublishLoading?: Uuid
}) {
    const loading = unpublishLoading === record.id

    return (
        <Row align={"middle"} justify={"center"}>
            {loading && <Spin />}
            {!loading && (
                <Checkbox
                    checked={record.published}
                    onChange={() => onChangeRow(record.id)}
                />
            )}
        </Row>
    )
}

export const ProducerAttributesValuesChooser: React.FC<
    ItemsGroupRowsChooserProps
> = (props) => {
    const comparator = new Intl.Collator()
    const {
        unpublishedValues,
        unpublishAttribute,
        unpublishLoading,
        setUnpublishLoading,
        loading,
    } = props

    const onChangeRow = (code: Uuid) => {
        setUnpublishLoading(code)
        return unpublishAttribute(code)
    }

    const filtredRows: TableData[] = useMemo(() => {
        const rows = props.rows || []
        return rows
            .map((el) => {
                return {
                    ...el,
                    published: !unpublishedValues.includes(el.id),
                }
            })
            .sort((a, b) => comparator.compare(a.label, b.label))
    }, [props.rows, unpublishedValues])

    return (
        <Spin wrapperClassName={styles.wrapperSpin} spinning={loading}>
            <Flex.Col fullWidth gap={10}>
                <UTable
                    disableListVirtualization
                    data={filtredRows}
                    search
                    searchCondition={(row, search) =>
                        row.label.toLowerCase().includes(search.toLowerCase())
                    }
                    columns={[
                        {
                            columnName: "ID",
                            width: 15,
                            render: (row) => row.id,
                        },
                        {
                            columnName: "Значение",
                            width: 25,
                            render: (row) => row.label,
                        },
                        {
                            columnName: "Альтернатива",
                            width: 25,
                            render: (item) => (
                                <DebounceInput
                                    initValue={item?.alt ?? ""}
                                    key={item.id}
                                    delay={400}
                                    inputProps={{
                                        bordered: false,
                                    }}
                                    resolve={(val) =>
                                        props.onChangeAltName({
                                            [item.id]: val,
                                        })
                                    }
                                />
                            ),
                        },
                        {
                            columnName: "Бух. название",
                            width: 25,
                            render: (item) => (
                                <DebounceInput
                                    initValue={item?.accounting ?? ""}
                                    key={item.id}
                                    delay={400}
                                    inputProps={{
                                        bordered: false,
                                    }}
                                    resolve={(val) =>
                                        props.onChangeAccountingName({
                                            [item.id]: val,
                                        })
                                    }
                                />
                            ),
                        },
                        {
                            columnName: "Опубликовано",
                            render: (item) => (
                                <PublishCheckbox
                                    record={item}
                                    onChangeRow={onChangeRow}
                                    unpublishLoading={unpublishLoading}
                                />
                            ),
                        },
                    ]}
                />
            </Flex.Col>
        </Spin>
    )
}
