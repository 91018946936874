import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { useEffect, useMemo } from "react"
import { ListingResponse } from "types/api"
import AccordionButtons from "../components/AccordionButtons"
import ItemsAccordion from "../components/NamingAccordion/NamingAccordion"
import { getFieldsForViewData, getTextAboutAvailabilityNaming } from "../helper"
import { useNameGenerationSettingsStore } from "../NameGenerationSettings.store"
import SettingSeries from "./SettingsSeries/SettingSeries"

const NameMasks = () => {
    const {
        activeGroupId,
        activeProducerId,
        setValue,
        setModal,
        allSeries,
        deleteAllSeriesAttributes,
        isRefetchSeries,
    } = useNameGenerationSettingsStore()

    const { data, isFetched, refetch } = useQuery({
        queryKey: [activeProducerId, activeGroupId],
        enabled: !!activeProducerId && !!activeGroupId,
        queryFn: async () => {
            const data = await api.getTyped<ListingResponse>("listing/series", {
                producer: activeProducerId,
                method: "asc",
                sort: "name",
                itemsGroup: activeGroupId,
                count_on_page: Number.MAX_SAFE_INTEGER,
            })

            return data.entities.map((item) => ({
                id: item.id,
                ...getFieldsForViewData(item.fields),
            }))
        },
        initialData: [],
    })

    useEffect(() => {
        setValue(data, "allSeries")
    }, [data])

    useEffect(() => {
        if (isRefetchSeries) {
            refetch()
        }
    }, [isRefetchSeries])

    const dataForAccordion = useMemo(
        () =>
            allSeries.map((item) => ({
                ...item,
                children: (
                    <SettingSeries
                        activeSeriesId={item.id}
                        {...item.attributes}
                    />
                ),
                extraClose: getTextAboutAvailabilityNaming(item),
                extraOpen: (
                    <AccordionButtons
                        clear={() =>
                            deleteAllSeriesAttributes({
                                id: item.id,
                            })
                        }
                        copyTo={() => {
                            setModal(true, "active")
                            setModal(item.id, "seriesId")
                        }}
                    />
                ),
            })),
        [allSeries]
    )

    return (
        <>
            <h5>Раскройте серию для настройки маски наименования</h5>
            <ItemsAccordion
                items={dataForAccordion}
                isFetched={isFetched}
                onClick={(val) => setValue(val, "series")}
            />
        </>
    )
}

export default NameMasks
