import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import ItemsAccordion from "pages/NameGenerationSettings/components/NamingAccordion/NamingAccordion"
import {
    getFieldsForViewData,
    getTextAboutAvailabilityNaming,
} from "pages/NameGenerationSettings/helper"
import { useNameGenerationSettingsStore } from "pages/NameGenerationSettings/NameGenerationSettings.store"
import { FC, useEffect, useMemo } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import AccordionButtons from "../../components/AccordionButtons"
import SettingsModel from "./SettingModel"

const SettingsModels: FC<{
    activeSeriesId: Uuid
}> = ({ activeSeriesId }) => {
    const {
        activeGroupId,
        deleteAllModelAttributes,
        setValue,
        setModal,
        allSeries,
        isRefetchModels,
    } = useNameGenerationSettingsStore()
    const allModels =
        allSeries.find((item) => item.id === activeSeriesId)?.models ?? []

    const { data, isFetched, refetch } = useQuery({
        queryKey: ["get-models", activeSeriesId, activeGroupId],
        enabled: !!activeGroupId && !!activeSeriesId,
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/model", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                    itemsGroups: activeGroupId,
                    series: activeSeriesId,
                })
                .then(async (data) => {
                    return data.entities.map((item) => ({
                        id: item.id,
                        ...getFieldsForViewData(item.fields),
                    }))
                }),
        initialData: [],
    })

    useEffect(() => {
        setValue({ id: activeSeriesId, data: data }, "allModels")
    }, [data])

    useEffect(() => {
        if (isRefetchModels) {
            refetch()
        }
    }, [isRefetchModels])

    const dataForAccordion = useMemo(
        () =>
            allModels.map((item) => ({
                ...item,
                children: (
                    <SettingsModel
                        label={item.label}
                        activeModelId={item.id}
                        activeSeriesId={activeSeriesId}
                        {...item.attributes}
                    />
                ),
                extraOpen: (
                    <AccordionButtons
                        clear={() =>
                            deleteAllModelAttributes({
                                idSeries: activeSeriesId,
                                idModel: item.id,
                            })
                        }
                        copyTo={() => {
                            setModal(true, "active")
                            setModal(item.id, "modelId")
                        }}
                    />
                ),
                extraClose: getTextAboutAvailabilityNaming(item),
            })),
        [allModels]
    )

    return (
        <ItemsAccordion
            items={dataForAccordion}
            isFetched={isFetched}
            onClick={(val) => setValue(val, "model")}
        />
    )
}

export default SettingsModels
