import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { Uuid } from "types"

const useStyles = createUseStyles({
    container: {
        maxHeight: "500px",
        width: "100%",
        borderRadius: "4px",
        border: "1px solid hsl(0, 0%, 80%)",
        background: "#fff",
        padding: "2px 8px",
        overflowY: "scroll",
        userSelect: "none",
    },
    containerDisabled: {
        backgroundColor: "hsl(0, 0%, 95%)",
        borderColor: "hsl(0, 0%, 90%)",
    },
    attributeLabel: {
        color: "#bbb",
        fontWeight: 600,
        fontSize: "12px",
        display: "inline-block",
    },
    attributeToggleLink: {
        color: "#0d6efd",
        fontSize: "12px",
        cursor: "pointer",
        paddingLeft: "10px !important",
        fontWeight: "400",
    },
    attributeValueClickable: {
        cursor: "pointer",
    },
    attributeContainer: {
        marginBottom: "10px",

        "&:last-child": {
            marginBottom: "0px",
        },
    },
})

const MassGenerationPicker = ({
    attributes,
    selectedIds,
    onChange,
    isDisabled,
}: {
    attributes: { label: string; values: Record<Uuid, string> }[]
    selectedIds: Set<Uuid>
    onChange: (id: Uuid, state: boolean) => void
    isDisabled: boolean
}) => {
    const comparator = new Intl.Collator()
    const classes = useStyles()

    const handleToggle = (id: Uuid, state: boolean) => {
        if (isDisabled) return
        onChange(id, state)
    }

    const handleToggleGroup = (values: Uuid[]) => {
        if (isDisabled) return

        const isSomethingNotChecked = values.some(
            (valueId) => !selectedIds.has(valueId)
        )

        if (isSomethingNotChecked) {
            return values.forEach((v) => handleToggle(v, true))
        }

        return values.forEach((v) => handleToggle(v, false))
    }

    return (
        <div
            className={clsx(
                classes.container,
                isDisabled && classes.containerDisabled
            )}
        >
            {attributes
                .sort((a, b) => comparator.compare(a.label, b.label))
                .map((attribute) => (
                    <div className={classes.attributeContainer}>
                        <div>
                            <div className={classes.attributeLabel}>
                                {attribute.label}
                                <span
                                    className={classes.attributeToggleLink}
                                    onClick={() =>
                                        handleToggleGroup(
                                            Object.keys(attribute.values)
                                        )
                                    }
                                >
                                    переключить
                                </span>
                            </div>
                        </div>

                        <div>
                            {Object.entries(attribute.values)
                                .sort(([, labelA], [, labelB]) => {
                                    const isAllValuesContainNumbers =
                                        !Object.entries(attribute.values).some(
                                            ([, v]) => !Number(v)
                                        )

                                    return isAllValuesContainNumbers
                                        ? Number(labelA) - Number(labelB)
                                        : comparator.compare(labelA, labelB)
                                })
                                .map(([id, label]) => (
                                    <div
                                        className={
                                            classes.attributeValueClickable
                                        }
                                        onClick={() =>
                                            handleToggle(
                                                id,
                                                !selectedIds.has(id)
                                            )
                                        }
                                    >
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedIds.has(id)}
                                            disabled={isDisabled}
                                        />{" "}
                                        {label}
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
        </div>
    )
}
export default MassGenerationPicker
