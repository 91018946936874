import type { QueryBuilderProps, RuleGroupType } from "react-querybuilder";
import { NomenclatureItem } from "types/api";

export type QueryBuilderItemData<T> = {
  query?: RuleGroupType;
} & T;

export const inputOperators = [
  "contains",
  "doesNotContain",
  "beginsWith",
  "doesNotBeginWith",
  "endsWith",
  "doesNotEndWith",
];

export const notFilterFields = ["ig", "m", "s", "p"];

export type Paginator = {
  allPages: number;
  currentPage: number;
  pageSize: number;
  totalCount: number;
};

export type GetNomenclatureRequest = {
  page: number;
  countOnPage: number;
  payload: {
    query?: RuleGroupType<any>;
  };
};

export type QueryFormFieldProps = QueryBuilderProps & {
  getEntitiesURL: string;
};

export type IQueryBuilderShowResult = {
  setOpenModal: (v: boolean) => void;
  openModal: boolean;
  loading: boolean;
  found?: NomenclatureItem[];
  pageSize: number;
  currentPage: number;
  totalPages: number;
  nextPage: () => void;
};
