import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { Uuid } from "types"
import { useDetailApiProps } from "./types"
import { usePageData } from "./usePageData"

export const useDetailApi = <InitialValues, CurrentValues>({
    pageSlug,
    transformInitialValues,
    init,
}: useDetailApiProps<InitialValues, CurrentValues>) => {
    const { id } = useParams() as { id?: Uuid }
    const { slug } = usePageData()

    const [currentValues, setCurrentValues] = useState<CurrentValues>(init)
    const [errors, setErrors] = useState<
        Partial<Record<keyof CurrentValues, string>>
    >({})

    const _pageSlug = pageSlug ?? slug

    const { data: initialValues, isFetching } = useQuery<InitialValues>({
        queryKey: ["get-initial-values", _pageSlug, id],
        enabled: !!id && !!_pageSlug,
        queryFn: () => api.getTyped<InitialValues>(`v1/${_pageSlug}/${id}`),
        onSuccess: (v) => setCurrentValues(transformInitialValues(v)),
    })

    const changeValue = useCallback(
        (
            key: keyof CurrentValues,
            value: CurrentValues[keyof CurrentValues],
            resetKeys?: Array<keyof CurrentValues>
        ) => {
            setCurrentValues((prev) => {
                if (!prev) return prev
                const newValue = {
                    ...prev,
                    [key]: value,
                }
                if (resetKeys) {
                    resetKeys.forEach((el) => {
                        newValue[el] = null as any
                    })
                }
                return newValue
            })
            setErrors({})
        },
        [setCurrentValues, setErrors]
    )

    const resetValues = useCallback(
        (keys: Array<keyof CurrentValues>) => {
            setCurrentValues((prev) => {
                const newValues = { ...prev } as any
                keys.forEach((key) => {
                    newValues[key] = null
                })
                return newValues
            })
        },
        [setCurrentValues]
    )

    const setError = useCallback(
        (v: keyof CurrentValues, error?: string) => {
            setErrors((prev) => ({
                ...prev,
                [v]: error ?? "Обязательное поле",
            }))
        },
        [setErrors]
    )

    const getError = useCallback(
        (v: keyof CurrentValues) => {
            return errors[v] ?? ""
        },
        [errors]
    )

    return {
        isFetching,
        initialValues,
        id,
        currentValues,
        changeValue,
        isReady: id ? !!initialValues : true,
        getError,
        resetValues,
        setError,
    }
}
