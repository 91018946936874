import { useEffect, useState } from "react";
import type { SelectEntity } from "../components/Selects/types";

export type UseSelectDataProps = {
  onGroupChange?: (v: SelectEntity | null) => void;
  onProducerChange?: (v: SelectEntity | null) => void;
  onModelChange?: (v: SelectEntity | null) => void;
  onSeriesChange?: (v: SelectEntity | null) => void;
  reset?: Symbol | null;
  showReset?: boolean;
  onApply?: () => void;
};

export const useSelectData = ({
  onGroupChange,
  onProducerChange,
  onModelChange,
  onSeriesChange,
  reset,
}: UseSelectDataProps) => {
  const [activeGroup, setActiveGroup] = useState<SelectEntity | null>(null);
  const [activeProducer, setActiveProducer] = useState<SelectEntity | null>(
    null
  );
  const [activeSeries, setActiveSeries] = useState<SelectEntity | null>(null);
  const [activeModel, setActiveModel] = useState<SelectEntity | null>(null);

  useEffect(() => {
    setActiveModel(null);
    onSeriesChange && onSeriesChange(activeSeries);
  }, [activeSeries]);

  useEffect(() => {
    setActiveSeries(null);
    onProducerChange && onProducerChange(activeProducer);
  }, [activeProducer]);

  useEffect(() => {
    setActiveProducer(null);
    onGroupChange && onGroupChange(activeGroup);
  }, [activeGroup]);

  useEffect(() => {
    onModelChange && onModelChange(activeModel);
  }, [activeModel]);

  useEffect(() => {
    if (reset) {
      setActiveModel(null);
      setActiveSeries(null);
      setActiveProducer(null);
      setActiveGroup(null);
    }
  }, [reset]);

  return {
    activeGroup,
    setActiveGroup,
    activeProducer,
    setActiveProducer,
    activeSeries,
    setActiveSeries,
    activeModel,
    setActiveModel,
  };
};
