import { Button, Col, Row, SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Flex } from "components/UI/Flex";
import { useSelectData, UseSelectDataProps } from "hooks/useSelectData";
import { HTMLAttributes } from "react";
import { GroupSelect } from "./GroupSelect";
import { ModelSelect } from "./ModelSelect";
import { ProducerSelect } from "./ProducerSelect";
import { SeriesSelect } from "./SeriesSelect";

export type EntitiesSelectFilterFunc = (data: DefaultOptionType) => boolean;

type Enitity = "model" | "series" | "producer";
export const EnititesSelect = (
  props: UseSelectDataProps & {
    containerStyles?: HTMLAttributes<any>["style"];
    entities?: Enitity[];
    size?: SelectProps["size"];
    onApplyIsActive?: boolean;
    filters?: {
      producer?: EntitiesSelectFilterFunc;
      itemsGroup?: EntitiesSelectFilterFunc;
      model?: EntitiesSelectFilterFunc;
      series?: EntitiesSelectFilterFunc;
    };
  }
) => {
  const {
    activeGroup,
    activeProducer,
    activeSeries,
    activeModel,
    setActiveGroup,
    setActiveProducer,
    setActiveSeries,
    setActiveModel,
  } = useSelectData(props);
  const {
    containerStyles = {},
    entities = ["model", "producer", "series"],
    size,
    showReset,
    onApply,
    onApplyIsActive,
  } = props;

  const showProducer = entities.includes("producer");
  const showSeries = entities.includes("series");
  const showModel = entities.includes("model");

  const reset = () => {
    setActiveGroup(null);
    setActiveProducer(null);
    setActiveModel(null);
    setActiveSeries(null);
  };

  return (
    <Row gutter={[0, 15]} style={{ ...containerStyles }}>
      <Col span={24}>
        <GroupSelect
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
          size={size}
          filter={props.filters?.itemsGroup}
        />
      </Col>

      {showProducer && (
        <Col span={24}>
          <ProducerSelect
            activeGroupId={activeGroup?.value}
            activeProducer={activeProducer}
            setActiveProducer={setActiveProducer}
            size={size}
          />
        </Col>
      )}

      {showSeries && (
        <Col span={24}>
          <SeriesSelect
            activeProducerId={activeProducer?.value}
            activeSeries={activeSeries}
            activeGroupId={activeGroup?.value}
            setActiveSeries={setActiveSeries}
            size={size}
          />
        </Col>
      )}

      {showModel && (
        <Col span={24}>
          <ModelSelect
            activeGroupId={activeGroup?.value}
            activeSeriesId={activeSeries?.value}
            activeModel={activeModel}
            setActiveModel={setActiveModel}
            size={size}
          />
        </Col>
      )}

      {(showReset || onApply) && (
        <Col span={24}>
          <Flex.Row fullWidth justify="start" gap={10}>
            {onApply && (
              <Button
                onClick={onApply}
                type="primary"
                disabled={
                  typeof onApplyIsActive === "boolean" && !onApplyIsActive
                }
              >
                Применить
              </Button>
            )}
            {showReset && <Button onClick={reset}>Сбросить</Button>}
          </Flex.Row>
        </Col>
      )}
    </Row>
  );
};
