import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { getFieldsForViewData } from "pages/NameGenerationSettings/helper"
import { Dispatch, SetStateAction, useEffect } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import TableWithCheckboxes from "../TableWithCheckboxes"

type Series = { id: Uuid; label: string }

interface ITableSeriesProps {
    selectedProducerId?: Uuid
    selectedGroupId?: Uuid
    setActiveSeries: (val: Series | null) => void
    activeSeries: Series | null
    values: Uuid[]
    setValues: Dispatch<SetStateAction<Uuid[]>>
    fromSeriesId?: Uuid
    selectedModels: Record<string, Uuid[]> | null
    setDataSeries: Dispatch<SetStateAction<Series[]>>
    activeDisabled: boolean
    setIsFetched: Dispatch<SetStateAction<boolean>>
}

const TableSeries = ({
    selectedProducerId,
    selectedGroupId,
    setActiveSeries,
    activeSeries,
    values,
    setValues,
    fromSeriesId,
    selectedModels,
    setDataSeries,
    activeDisabled,
    setIsFetched,
}: ITableSeriesProps) => {
    const { data, isFetched } = useQuery({
        queryKey: [selectedProducerId, selectedGroupId, "get-series"],
        enabled: !!selectedProducerId && !!selectedGroupId,
        queryFn: async () => {
            const data = await api.getTyped<ListingResponse>("listing/series", {
                producer: selectedProducerId,
                method: "asc",
                sort: "name",
                itemsGroup: selectedGroupId,
                count_on_page: Number.MAX_SAFE_INTEGER,
            })

            return data.entities?.map((item) => ({
                id: item.id,
                label: getFieldsForViewData(item.fields).label as string,
                active: false,
            }))
        },
        initialData: [],
    })

    const onAllCheckboxClick = () => {
        setValues((state) => {
            const currentData = data
                .filter((item) => item.id !== fromSeriesId)
                .map((item) => item.id)

            if (currentData.every((item) => state.includes(item))) {
                return []
            }

            return currentData
        })
    }

    const onCheckboxClick = (val: Uuid) => {
        setValues((state) =>
            state.includes(val)
                ? state.filter((item) => item !== val)
                : [...state, val]
        )
    }

    useEffect(() => {
        setIsFetched(isFetched)
        setDataSeries(data)
        setActiveSeries(data[0])
    }, [isFetched, data])

    return (
        <TableWithCheckboxes
            label={`Все серии`}
            data={data}
            disabledId={activeDisabled ? fromSeriesId : undefined}
            onRowClick={(row) => setActiveSeries(row as Series)}
            isRowActive={(row) => (row as Series).id === activeSeries?.id}
            loading={!isFetched && !!selectedGroupId && !!selectedProducerId}
            values={values}
            onCheckboxClick={onCheckboxClick}
            onAllCheckboxClick={onAllCheckboxClick}
            getIndeterminateValue={(row) => !!selectedModels?.[row.id]?.length}
        />
    )
}

export default TableSeries
