import { useQuery } from "@tanstack/react-query"
import { Col, Row, Select } from "antd"
import { DefaultOptionType, SelectProps } from "antd/es/select"
import clsx from "clsx"
import { filterOption } from "components/inputs/helpers"
import { Text } from "components/UI"
import api from "helpers/api"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import { SelectEntity } from "../types"
import { EntitiesSelectFilterFunc } from "./EntitiesSelect"
import { getOnSelectData } from "./onSelect"

interface GroupSelectProps {
    setActiveGroup: (v: SelectEntity | null) => void
    activeGroup: SelectEntity | null
    required?: boolean
    layout?: "vertical" | "horizontal"
    skipValues?: Uuid[]
    size?: SelectProps["size"]
    labelSpan?: number
    inputSpan?: number
    formLabelFontSize?: number
    fullWidth?: boolean
    containerClassName?: string
    filter?: EntitiesSelectFilterFunc
}

export function GroupSelect({
    setActiveGroup,
    required,
    layout = "horizontal",
    activeGroup,
    size,
    labelSpan,
    inputSpan,
    formLabelFontSize,
    fullWidth,
    containerClassName,
    filter,
    ...rest
}: GroupSelectProps & SelectProps) {
    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: ["get-items-group"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/items-group", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) => {
                    const options = response.entities.map(
                        (el) =>
                            ({
                                label: el.fields.find(
                                    (field) => field.name === "name"
                                )!.value,
                                value: el.fields.find(
                                    (field) => field.name === "id"
                                )!.value,
                            } as DefaultOptionType)
                    )
                    return filter
                        ? options.filter((option) => filter(option))
                        : options
                }),
        initialData: [],
    })

    const _labelSpan = layout === "horizontal" ? labelSpan ?? 5 : 24
    const _inputSpan = layout === "horizontal" ? inputSpan ?? 19 : 24

    const onChange = (v: any) => {
        setActiveGroup(v ? getOnSelectData(v) : null)
    }

    return (
        <Row
            gutter={[0, 10]}
            className={clsx([
                {
                    fw: fullWidth,
                },
                containerClassName,
            ])}
        >
            <Col xs={24} md={_labelSpan}>
                <Text.FormLabel
                    formLabelFontSize={formLabelFontSize}
                    required={required}
                >
                    Группа
                </Text.FormLabel>
            </Col>
            <Col xs={24} md={_inputSpan}>
                <Select
                    size={size}
                    loading={isFetching}
                    showSearch
                    filterOption={filterOption}
                    className="fw"
                    placeholder="Выберите группу"
                    options={data}
                    value={activeGroup}
                    onChange={(_, option) => onChange(option)}
                    allowClear
                    {...rest}
                />
            </Col>
        </Row>
    )
}
