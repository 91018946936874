import classes from "./AddItemButton.module.sass";

const AddItemButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={classes.addItem} onClick={onClick}>
      <div className={classes.addItemCircle}>
        <div className={classes.addItemCircle__vertical} />
        <div className={classes.addItemCircle__horizontal} />
      </div>
      <div className={classes.addItemLine} />
    </div>
  );
};

export default AddItemButton;
