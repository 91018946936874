import { CheckOutlined } from "@ant-design/icons"
import { Button } from "antd"
import api from "helpers/api"
import { Dispatch, SetStateAction } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"

interface ButtonSelectAllSettingsProps {
    selectedProducerId?: Uuid
    selectedGroupId?: Uuid
    setIsLoading: (val: boolean) => void
    setSelectedSeries: Dispatch<SetStateAction<Uuid[]>>
    setSelectedModels: Dispatch<SetStateAction<Record<Uuid, string[]> | null>>
    fromSeriesId?: Uuid
    fromModelId?: Uuid
    dataSeries: any[]
    isDisabledSeries: boolean
}

const ButtonSelectAllSettings = ({
    selectedProducerId,
    selectedGroupId,
    setIsLoading,
    setSelectedSeries,
    setSelectedModels,
    fromSeriesId,
    fromModelId,
    dataSeries,
    isDisabledSeries,
}: ButtonSelectAllSettingsProps) => {
    const setAllItems = async () => {
        try {
            setIsLoading(true)
            const seriesIds = dataSeries.map((item) => item.id) as Uuid[]

            const allResModels = (await Promise.all(
                seriesIds.map(async (series) => {
                    const res = await api.getTyped<ListingResponse>(
                        "listing/model",
                        {
                            method: "asc",
                            sort: "name",
                            count_on_page: Number.MAX_SAFE_INTEGER,
                            itemsGroups: selectedGroupId,
                            series: series,
                        }
                    )

                    return {
                        [series]: res.entities
                            .map((model) => model.id)
                            .filter((item) => item !== fromModelId),
                    }
                })
            ).then((results) =>
                results.reduce((acc, result) => ({ ...acc, ...result }), {})
            )) as Record<Uuid, string[]>

            setSelectedSeries(
                isDisabledSeries
                    ? seriesIds.filter((item) => item !== fromSeriesId)
                    : seriesIds
            )
            setSelectedModels(allResModels)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const idDisabled =
        !selectedGroupId || !selectedProducerId || !dataSeries.length

    return (
        <Button
            icon={<CheckOutlined />}
            type="primary"
            onClick={setAllItems}
            ghost
            disabled={idDisabled}
        >
            Выбрать все серии и модели
        </Button>
    )
}

export default ButtonSelectAllSettings
